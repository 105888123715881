const result = {
    "11":[
        {"seq":1,"title":"KOREAN VALUE BOX [CUP]","price": 20000 ,"img":"1004.jpg", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":2,"title":"KOREAN VALUE BOX [CUP]","price": 20000 ,"img":"1004.jpg", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":3,"title":"KOREAN VALUE BOX [CUP]","price": 20000 ,"img":"1004.jpg", "url":"https://smartstore.naver.com/salondeobjet"}
    ],
    "12":[
        {"seq":1,"title":"KOREAN VALUE BOX [bowl]","price": 45000 ,"img":"1002.jpg", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":2,"title":"KOREAN VALUE BOX [bowl]","price": 45000 ,"img":"1002.jpg", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":3,"title":"KOREAN VALUE BOX [bowl]","price": 45000 ,"img":"1002.jpg", "url":"https://smartstore.naver.com/salondeobjet"}
    ],
    "13":[
        {"seq":1,"title":"KOREAN VALUE BOX [box]","price": 88000 ,"img":"1003.jpg", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":2,"title":"KOREAN VALUE BOX [box]","price": 88000 ,"img":"1003.jpg", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":3,"title":"KOREAN VALUE BOX [box]","price": 88000 ,"img":"1003.jpg", "url":"https://smartstore.naver.com/salondeobjet"}
    ],
    "14":[
        {"seq":1,"title":"KOREAN VALUE BOX [소반]","price": 140000 ,"img":"1005.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":2,"title":"KOREAN VALUE BOX [소반]","price": 140000 ,"img":"1005.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":3,"title":"KOREAN VALUE BOX [소반]","price": 140000 ,"img":"1005.png", "url":"https://smartstore.naver.com/salondeobjet"}
    ],
    "21":[
        {"seq":1,"title":"일월오봉도 케이스","price": 18800 ,"img":"1027.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":2,"title":"조각보 왕관 책갈피","price": 15000 ,"img":"1032.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":3,"title":"전통문양 그립톡","price": 12000 ,"img":"1030.png", "url":"https://smartstore.naver.com/salondeobjet"}
    ],
    "22":[
        {"seq":1,"title":"보상화 손거울 [한지]","price": 35000 ,"img":"1019.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":2,"title":"단청 텀블러","price": 39600 ,"img":"1012.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":3,"title":"핀배지 꾸러미","price": 33000 ,"img":"1036.png", "url":"https://smartstore.naver.com/salondeobjet"}
    ],
    "23":[
        {"seq":1,"title":"단청 장우산","price": 99900 ,"img":"1011.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":2,"title":"자개 무선 충전기","price": 99000 ,"img":"1028.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":3,"title":"조각보 필통 [한지]","price": 70000 ,"img":"1033.png", "url":"https://smartstore.naver.com/salondeobjet"}
    ],
    "24":[
        {"seq":1,"title":"3단 서랍장 [한지]","price": 150000 ,"img":"1001.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":2,"title":"여행지갑","price": 250000 ,"img":"1040.jpg", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":3,"title":"흉배 쿠션 [한복 소재]","price": 350000 ,"img":"1038.jpg", "url":"https://smartstore.naver.com/salondeobjet"}
    ],
    "31":[
        {"seq":1,"title":"대한민국 국보 [도서]","price": 20000 ,"img":"1014.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":2,"title":"윷놀이","price": 15000 ,"img":"1026.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":3,"title":"한옥마을정문 만들기DIY","price": 12000 ,"img":"1037.png", "url":"https://smartstore.naver.com/salondeobjet"}
    ],
    "32":[
        {"seq":1,"title":"고누 [전통놀이]","price": 27000 ,"img":"1006.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":2,"title":"문구 꾸러미","price": 32000 ,"img":"1015.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":3,"title":"석호 향꽂이","price": 55000 ,"img":"1021.png", "url":"https://smartstore.naver.com/salondeobjet"}
    ],
    "33":[
        {"seq":1,"title":"김기진 서예가 부채","price": 87000 ,"img":"1008.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":2,"title":"양단 코스터 꾸러미 [한지]","price": 70000 ,"img":"1023.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":3,"title":"자개 무선 충전기","price": 99000 ,"img":"1028.png", "url":"https://smartstore.naver.com/salondeobjet"}
    ],
    "34":[
        {"seq":1,"title":"골무 [바느질]","price": 110000 ,"img":"1007.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":2,"title":"조각보 [바느질]","price": 150000 ,"img":"1031.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":3,"title":"흉배 쿠션 [한복 소재]","price": 350000 ,"img":"1038.jpg", "url":"https://smartstore.naver.com/salondeobjet"}
    ],
    "41":[
        {"seq":1,"title":"복주머니 [명주바느질]","price": 18000 ,"img":"1020.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":2,"title":"흉배파우치","price": 28000 ,"img":"1039.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":3,"title":"버선 키체인 [명주바느질]","price": 18000 ,"img":"1018.png", "url":"https://smartstore.naver.com/salondeobjet"}
    ],
    "42":[
        {"seq":1,"title":"단청 단우산","price": 62800 ,"img":"1010.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":2,"title":"단청 단우산","price": 62800 ,"img":"1010.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":3,"title":"달항아리 middle [도자기]","price": 52000 ,"img":"1013.png", "url":"https://smartstore.naver.com/salondeobjet"}
    ],
    "43":[
        {"seq":1,"title":"단청 장우산","price": 99900 ,"img":"1011.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":2,"title":"조각보 필통 [한지]","price": 70000 ,"img":"1033.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":3,"title":"반지 싸개 [바느질]","price": 87000 ,"img":"1017.png", "url":"https://smartstore.naver.com/salondeobjet"}
    ],
    "44":[
        {"seq":1,"title":"자연염색 스카프","price": 130000 ,"img":"1029.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":2,"title":"흉배 쿠션 [한복 소재]","price": 350000 ,"img":"1038.jpg", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":3,"title":"조각보 [바느질]","price": 150000 ,"img":"1031.png", "url":"https://smartstore.naver.com/salondeobjet"}
    ],
    "51":[
        {"seq":1,"title":"꽃 손거울 [한지]","price": 25000 ,"img":"1009.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":2,"title":"청자 달항아리 mini [도자기]","price": 18000 ,"img":"1034.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":3,"title":"민씨가옥 사랑채DIY","price": 22000 ,"img":"1016.png", "url":"https://smartstore.naver.com/salondeobjet"}
    ],
    "52":[
        {"seq":1,"title":"손수건 꾸러미","price": 45000 ,"img":"1022.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":2,"title":"석호 향꽂이","price": 55000 ,"img":"1021.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":3,"title":"옹기 항아리 미니어쳐 꾸러미","price": 55000 ,"img":"1025.png", "url":"https://smartstore.naver.com/salondeobjet"}
    ],
    "53":[
        {"seq":1,"title":"자개 무선 충전기","price": 99000 ,"img":"1028.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":2,"title":"김기진 서예가 부채","price": 87000 ,"img":"1008.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":3,"title":"반지 싸개 [바느질]","price": 87000 ,"img":"1017.png", "url":"https://smartstore.naver.com/salondeobjet"}
    ],
    "54":[
        {"seq":1,"title":"팔각 소반 [목공예]","price": 320000 ,"img":"1035.png", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":2,"title":"여행지갑","price": 250000 ,"img":"1040.jpg", "url":"https://smartstore.naver.com/salondeobjet"},
        {"seq":3,"title":"흉배 쿠션 [한복 소재]","price": 350000 ,"img":"1038.jpg", "url":"https://smartstore.naver.com/salondeobjet"}
    ]
};

export default result;