const question = [
    {
        type: "weight",
        title: "선물 받을 사람 or 나는 Youtube에서 <br>K-POP 또는 K-Drama 를 본적이 있나요? ",
        content: [
            {
                answer: "YES",
                weight: [1, 0, 0]
            },
            {
                answer: "NO",
                weight: [0, 1, 0]
            },
            {
                answer: "잘 모르겠다.",
                weight: [0, 0, 1]
            }
        ]
    },
    {
        type: "filter",
        title: "어떤 종류의 컨텐츠를 원하세요?",
        content: [
            {
                answer: "맛",
                filter: 1
            },
            {
                answer: "문양",
                filter: 2
            },
            {
                answer: "이야기",
                filter: 3
            },
            {
                answer: "색",
                filter: 4
            },
            {
                answer: "소재",
                filter: 5
            }
        ]
    },
    {
        type: "weight",
        title: "선물 받을 사람 or 나의 국적은 어딘가요?",
        content: [
            {
                answer: "미주&유럽",
                weight: [0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
                answer: "아시아",
                weight: [0, 0, 0, 0, 0, 0, 0, 0]
            }
        ]
    },
    {
        type: "weight",
        title: "선물 받을 사람 or 나의 나이는요?",
        content: [
            {
                answer: "~30대",
                weight: [0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
                answer: "40대",
                weight: [0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
                answer: "50대 이상",
                weight: [0, 0, 0, 0, 0, 0, 0, 0]
            }
        ]
    },
    {
        type: "filter",
        title: "금액대는 얼마가 좋을까요?",
        content: [
            {
                answer: "3만원 미만",
                filter: 1
            },
            {
                answer: "3~7만원",
                filter: 2
            },
            {
                answer: "7~10만원",
                filter: 3
            },
            {
                answer: "10만원 이상",
                filter: 4
            }
        ]
    }
];

export default question;