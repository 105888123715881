import React from 'react';
import MainCard from './MainCard';
const Main = () => {
    return (
        <>
            <MainCard />
        </>
    );   
};

export default Main;