import React from 'react';
import Main from '../components/common/Main';

function MainPage() {
    return (
        <>
            <Main />
        </>
    );
}
export default MainPage;